// @file Helper functions for color manipulation

interface RGB {
  r: number
  g: number
  b: number
}

interface RGBA extends RGB {
  a: number
}

function rgba(r: number, g: number, b: number, a: number): RGBA {
  return { r, g, b, a }
}

/**
 * Calculate ratio based on relative luminance
 * @see https://www.w3.org/TR/WCAG20-TECHS/G17.html
 * @see https://en.wikipedia.org/wiki/Relative_luminance
 * @see https://developer.mozilla.org/en-US/docs/Web/Accessibility/Understanding_Colors_and_Luminance
 * @see https://webaim.org/resources/contrastchecker/ -- tool to calculating ratio for 2 colors
 */
function computeLuminanceRatio(e1: RGB | RGBA, e2: RGB | RGBA): number {
  const l1 = (0.2126 * e1.r) / 255 + (0.7152 * e1.g) / 255 + (0.0722 * e1.b) / 255
  const l2 = (0.2126 * e2.r) / 255 + (0.7152 * e2.g) / 255 + (0.0722 * e2.b) / 255
  const ratio = (l1 + 0.05) / (l2 + 0.05)
  return ratio > 1 ? ratio : 1 / ratio
}

function serializeRgbaStringToRgba(rgbaString: string): RGBA {
  const imageBackgroundArray = rgbaString.replace(/(a|b|g|r|\(|\)|\s)/g, '').split(',')
  return {
    r: parseInt(imageBackgroundArray[0], 10),
    g: parseInt(imageBackgroundArray[1], 10),
    b: parseInt(imageBackgroundArray[2], 10),
    a: imageBackgroundArray.length === 4 ? parseInt(imageBackgroundArray[3], 10) : 1,
  }
}

/**
 * Change color brightness
 * @param color Original color, either a RBG object or a string
 * @param percent > 0 to lighten, < 0 to darken
 * @returns RGB color string
 */
function shadeColor(color: RGB | string, percent: number): string {
  let { r, g, b } = typeof color === 'string' ? serializeRgbaStringToRgba(color) : color

  r = parseInt(String((r * (100 + percent)) / 100))
  g = parseInt(String((g * (100 + percent)) / 100))
  b = parseInt(String((b * (100 + percent)) / 100))

  r = Math.min(r, 255)
  g = Math.min(g, 255)
  b = Math.min(b, 255)

  return `rgb(${r}, ${g}, ${b})`
}

function shadeColorWithOpacity(baseColor: RGB, overlayColor: RGB, opacityPercentage: number): RGB {
  // Convert percentage to a decimal (e.g., 10% becomes 0.1)
  let opacity = opacityPercentage / 100

  // Ensure opacity is between 0 and 1
  opacity = Math.max(0, Math.min(1, opacity))

  // Apply the alpha blending formula
  const shadedColor: RGB = {
    r: Math.round((1 - opacity) * baseColor.r + opacity * overlayColor.r),
    g: Math.round((1 - opacity) * baseColor.g + opacity * overlayColor.g),
    b: Math.round((1 - opacity) * baseColor.b + opacity * overlayColor.b),
  }

  return shadedColor
}
function convertHexToRGB(hex: string): RGB | null {
  // Check for shorthand form (e.g. "#03F") and convert to full form (e.g. "#0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  hex = hex.replace(shorthandRegex, function (_, r: string, g: string, b: string) {
    return r + r + g + g + b + b
  })

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  if (result !== null) {
    return {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    }
  }

  return null
}

export { computeLuminanceRatio, convertHexToRGB, rgba, serializeRgbaStringToRgba, shadeColor, shadeColorWithOpacity }
export type { RGB, RGBA }
